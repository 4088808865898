<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
    <v-footer dark padless>
      <v-col class="text-center caption" cols="12">
        <v-btn to="/" text class="mr-4">Home</v-btn>
        <v-btn to="/impressum" text class="mr-4">Impressum</v-btn>
        <v-btn to="/datenschutz" text class="mr-4">Datenschutz</v-btn>
      </v-col>
      <v-col
        class="text-center"
        cols="12"
      > 
        Copyright <strong>Gerrit Haake</strong> &copy; {{ new Date().getFullYear() }} 
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
