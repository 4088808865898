<template>
<v-container>
  <v-row justify="center">
    <v-col md="6" sm="12">
      <Information />
    </v-col>
  </v-row>
  <!-- <v-row justify="center">
    <v-col md="6" sm="12">
      <GitHub />
    </v-col>
  </v-row>
  <v-row justify="center">
    <v-col md="6" sm="12">
      <WorkInfo />
    </v-col>
  </v-row> -->
</v-container>
</template>

<script>
  import Information from '../components/Information'
  // import GitHub from '../components/GithubProject'
  // import WorkInfo from '../components/WorkInfo'

  export default {
    name: 'Home',

    components: {
      Information,
      // GitHub,
      // WorkInfo
    },
  }
</script>
