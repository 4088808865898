<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-card dark elevation="4" class="rounded">
          <v-card-text>
            <v-container>
              <v-avatar size="200" color="white">
                <!-- <img
                  alt="user"
                  src="https://cdn.pixabay.com/photo/2020/06/24/19/12/cabbage-5337431_1280.jpg"
                > -->
                <span class="font-weight-black display-4 black--text">GH</span>
              </v-avatar>
            </v-container>

            <v-container class="font-weight-bold">
              <strong>{{ personal.fullname }}</strong>
              <div> {{ personal.title }} </div>
              <div> {{ personal.degree }} </div>
              <div> @{{ personal.addy }} </div>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            
            <div v-for="(item, id) in contact" :key="id">
              <v-btn icon large :color="item.color" :href="item.href" target="_blank" class="ml-4 mr-4">
                <SvgIcon :size="item.size || 36" type="mdi" :path="item.icon"></SvgIcon>
              </v-btn>
            </div>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon'
import { mdiGithub
       , mdiMail
       , mdiHome
       , mdiAlphaX } from '@mdi/js'

  export default {
    name: 'HelloWorld',

    components: {
        SvgIcon
    },

    data: () => ({
      personal: {
        fullname: "Gerrit Haake",
        title: "Softwareentwickler | Full-Stack",
        degree: "M. Sc. Informatik",
        addy: "ghaake"
      },
      contact: [
        {
          icon: mdiMail, href: "mailto:info@gerrithaake.de", color: ""
        },
        {
          icon: mdiHome, href: "https://gerrithaake.de", color: "blue"
        },
        {
          icon: mdiGithub, href: "https://github.com/ghaake", color: ""
        },
        {
          icon: mdiAlphaX, href: "https://www.xing.com/profile/GerritChristopher_Haake/", color: "green", size: "48"
        }
      ]
    }),
  }
</script>

<style scoped>
.rounded 
{
  border-radius: 30px;
}
</style>
